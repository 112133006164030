import { getResolution } from '@backmarket/http-api/src/api-specs-after-sale-experience/resolution-engine/endpoints'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export function useResolution({
  resolutionId,
  options = { immediate: true },
}: {
  resolutionId: string
  options?: { immediate: boolean }
}) {
  return useHttpFetch(getResolution, {
    pathParams: {
      resolutionId,
    },
    ...options,
  })
}
