import { createHttpEndpoint } from '../../utils'

import type { CreateShipmentData } from './types/createShipment'
import type { GetResolutionOptionsData } from './types/getResolutionOptions'
import type { GetResolutionsData } from './types/getResolutions'
import type { InitiateResolutionData } from './types/initiateResolution'
import type { ResolutionResponse } from './types/resolutionResponse'

/**
 * Return list of eligible resolution options given a declaredProblem
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-resolution-engine-api/definition#tag/resolution-engine/operation/getResolutionOptions}
 */
export const getResolutionOptions =
  createHttpEndpoint<GetResolutionOptionsData>({
    method: 'POST',
    operationId: 'getResolutionOptions',
    path: '/bm/resolution-engine/v1/resolution-options',
  })

/**
 * Initiate a Resolution and returns the corresponding resolution
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-resolution-engine-api/definition#tag/resolution-engine/operation/initiateResolution}
 */
export const initiateResolution = createHttpEndpoint<InitiateResolutionData>({
  method: 'POST',
  operationId: 'initiateResolution',
  path: '/bm/resolution-engine/v1/resolutions',
})

/**
 * Get the resolutions based on an orderline_id, a resolution_folder_id or a state
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-resolution-engine-api/definition#tag/resolution-engine/operation/getResolutions}
 */
export const getResolutions = createHttpEndpoint<GetResolutionsData>({
  method: 'GET',
  operationId: 'getResolutions',
  path: '/bm/resolution-engine/v1/resolutions',
})

/**
 * Get the resolution based on a resolution_id
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/after-sale-resolution-engine-api/definition#tag/resolution-engine/operation/getResolutions}
 */
export const getResolution = createHttpEndpoint<ResolutionResponse>({
  method: 'GET',
  operationId: 'getResolution',
  path: '/bm/resolution-engine/v1/resolutions/:resolutionId',
})

/**
 * Create shipment
 *
 * @see {@link https://devportal.backmarket.io/catalog/default/api/resolution-engine-api/definition#/web/create_shipment}
 */
export const postShipment = createHttpEndpoint<CreateShipmentData>({
  method: 'POST',
  operationId: 'createShipment',
  path: '/resolution-engine/shipments',
})
